"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const appConfig = {
    theme: {
        dark: true,
    },
    appName: 'PALACE  Premium',
    organizationId: 'VSvDgEQqzzzBQ9yL0Tcm',
    version: '1.0.0',
    ionicAppId: '',
    applicationKey: 'palace',
    applicationType: 'standalone',
    artistId: 'IhGzBSA1giavqTvvTQDZ4mRccgl2',
    languages: ['ja', 'en'],
    ios: {
        bundleId: 'com.utoniq.palace',
        appId: '',
    },
    android: {
        bundleId: 'com.utoniq.palace',
    },
    platform: ['web'],
    custom: {
        modules: {
            tutorial: false,
            trade: false,
            membership: true,
            thread: true,
            prepaidPoint: null,
            gacha: false,
            nft: false,
            liveStream: true,
            item: true,
            store: true,
            campaign: false,
            mission: true,
            resale: false,
            preSale: true,
            event: true,
            cart: true,
            pickup: false,
            eprint: false,
            konbiniPayment: true,
        },
        tutorials: [
            'images/tutorial/Tutorial01.jpg',
            'images/tutorial/Tutorial02.jpg',
            'images/tutorial/Tutorial03.jpg',
            'images/tutorial/Tutorial04.jpg',
        ],
        globalTabs: [],
        artistTabs: ['home', 'posts', 'notifications', 'myProfile'],
    },
    deeplinkUrl: 'https://palace.utoniq.com',
    helpUrl: 'https://help.utoniq.com/ja/collections/2299305',
    storagePath: 'palace',
    dynamicLinkPrefix: 'palace',
};
exports.default = appConfig;
